<template>
  <header class="adminHeader">
    <div class="title">
      <img
        class="logo"
        src="@/assets/fhgNewsLogoHorizontal.svg"
        alt="FHG News"
      />
      <h1 class="text">Gutscheine</h1>
    </div>
    <div class="buttons">
      <v-btn
        icon
        class="home button"
        exact
        :color="$route.path == homeLink ? 'primary' : null"
        :to="homeLink"
      >
        <v-icon>home</v-icon>
      </v-btn>
      <v-btn icon class="support button" @click="supportDialog = true">
        <v-icon> support_agent</v-icon>
      </v-btn>
      <v-btn icon class="auth button" to="/login" v-if="!$userProfile">
        <v-icon>person</v-icon>
      </v-btn>
      <v-menu v-if="$userProfile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="auth button" v-on="on" v-bind="attrs">
            <v-icon>person</v-icon>
          </v-btn>
        </template>
        <v-card class="auth-popup" max-width="350">
          <v-list>
            <v-list-item class="details">
              <v-list-item-content>
                <v-list-item-title>{{
                  !!$userProfile ? $userProfile.name : ''
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  !!$userProfile
                    ? $userProfile.admin
                      ? 'Administrator'
                      : $userProfile.canDebit &&
                        Array.isArray($userProfile.canDebit) &&
                        $userProfile.canDebit.length
                      ? 'Gutschein-Abbuchung bei ' +
                        $userProfile.canDebit.length +
                        ' Kampagne' +
                        ($userProfile.canDebit.length != 1 ? 'n' : '')
                      : 'Keine Berechtigungen'
                    : ''
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content class="item email">
                <v-list-item-subtitle class="item__subtitle"
                  >E-Mail</v-list-item-subtitle
                >
                <v-list-item-title class="item__title">{{
                  $userProfile ? $userProfile.email : ''
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openChangeEmailDialog">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item class="item logout" @click="logout">
                <v-list-item-avatar>
                  <v-icon>exit_to_app</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="item__title"
                    >Abmelden</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <v-dialog v-model="changeEmailDialog.active" persistent max-width="500">
      <v-card>
        <v-card-title style="word-break: break-word;">
          E-Mail ändern
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              class="email input"
              outlined
              label="E-Mail"
              placeholder="max.mustermann"
              suffix="@…"
              hint="Gib deinen IServ Nutzernamen ein"
              v-model="changeEmailDialog.email"
              :rules="[rules.required]"
              tabindex="1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="changeEmailDialog.active = false"
            >Abbrechen</v-btn
          >
          <v-btn text color="primary" @click="changeEmail">Ändern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'unknown'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Ändern der E-Mail
          </v-card-title>
          <v-card-text>
            Als wir versucht haben deine E-Mail-Adresse zu ändern, ist ein
            unerwarteter Feher aufgetreten. Bitte versuche es später erneut oder
            kontaktiere den Support.<br />
            Fehlercode: {{ error.catched }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="error = { active: false, code: '', catched: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="supportDialog"
      v-model="supportDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title style="word-break: break-word;">
          WebApp in der Testphase
        </v-card-title>
        <v-card-text>
          <p>
            Diese WebApp befindet sich in der Testphase und ist noch nicht
            vollumfänglich ausgereift. Daher können vermehrt Fehler auftreten.
          </p>
          <p>
            Falls du einen Fehler gefunden hast oder technische Unterstützung
            brauchst, schreibe uns gerne eine E-Mail oder kontaktiere bei
            dringenden Fällen unseren technischen Support.
          </p>
        </v-card-text>
        <v-card-actions class="actionButtons">
          <v-spacer></v-spacer>
          <v-btn text @click="supportDialog = false">Abbrechen</v-btn>
          <v-btn
            text
            href="https://wa.me/+4917643811166?text=Hallo+FHG+News+Gutscheine-Support+Team%2C+hier+ist+%3CDEIN+NAME%3E.+Ich+brauche+Unterst%C3%BCtzung+bei+..."
            color="primary"
            >Support anschreiben</v-btn
          >
          <v-btn text href="tel:+4917643811166" color="primary"
            >Support anrufen</v-btn
          >
          <v-btn text href="mailto:redaktion@fhgnews.de" color="primary"
            >E-Mail schreiben</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.active" timeout="2000">
      {{ snackbar.message }}
    </v-snackbar>
  </header>
</template>
<script>
import { db, auth } from '@/firebase';

export default {
  name: 'AdminHeader',
  data() {
    return {
      changeEmailDialog: {
        active: false,
        email: '',
      },
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'E-Mail inkorrekt',
      },
      error: {
        active: false,
        code: '',
        catched: null,
      },
      snackbar: {
        active: false,
        message: '',
      },
      supportDialog: false,
    };
  },
  computed: {
    homeLink() {
      console.log(this.$userProfile);
      if (!this.$userProfile) return '/';
      if (this.$userProfile.admin) return '/admin';
      if (this.$userProfile.canDebit.length) return '/d';
      return '/';
    },
  },
  methods: {
    openChangeEmailDialog() {
      this.changeEmailDialog = {
        active: true,
        email: this.$userProfile.email.split('@')[0],
      };
    },
    async changeEmail() {
      this.changeEmailDialog.loading = true;
      try {
        const email =
          this.changeEmailDialog.email + '@franz-haniel-gymnasium.eu';
        await db.doc(`users/${auth.currentUser.uid}`).update({ email });
        this.snackbar = {
          active: true,
          message: 'E-Mail erfolgreich geändert',
        };
      } catch (error) {
        this.error = {
          active: true,
          code: 'unknown',
          catched: error,
        };
      } finally {
        this.changeEmailDialog.active = false;
        this.changeEmailDialog.loading = false;
      }
    },
    async logout() {
      await auth.signOut();
      this.$userProfile = null;
      this.$router.replace({ path: `/login?redirect=${this.$route.path}` });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.adminHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 0 24px 0;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    justify-content: space-between;
  }

  .title {
    margin-right: 24px;

    .logo {
      padding-left: 4px;
      height: 12px;
      width: auto;
    }

    .text {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      line-height: 1;
    }
  }

  .buttons {
    .button {
      margin-left: 8px;

      &.home {
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

.auth-popup {
  border-radius: 8px;

  .details {
    padding: 8px 16px;
  }

  .item {
    .item__title {
      font-size: 14px;
    }

    .item__subtitle {
      font-size: 12px;
    }
  }
}

.supportDialog {
  .actionButtons {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
</style>
